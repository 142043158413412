var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"div-search"},[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"3","xl":"3"}},[_c('div',{staticClass:"molded"},[_c('div',{staticClass:"div-select"},[_c('SelectStatic',{attrs:{"fieldTarget":"filter","classCss":"select-search","onlyQuery":true,"initialValue":{
              content: 'Número da Locação',
              id: 'rentNumber',
            },"showBorder":false,"data":[
              { content: 'Número da Locação', id: 'rentNumber' },
              { content: 'Nome do Cliente', id: 'customerName' },
              { content: 'CNPJ/CPF', id: 'customerDocument' },
              { content: 'Nome do Produto', id: 'productName' },
              { content: 'Número do Patrimônio', id: 'patrimonyBarCode' } ]},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)])]),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('div',{staticClass:"molded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"input-search",attrs:{"type":"text","name":"search","placeholder":"Pesquisar ..."},domProps:{"value":(_vm.search)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.execute()},"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})])]),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"3","xl":"3"}},[_c('div',{staticClass:"div-btn"},[_c('Button',{attrs:{"_key":"btnSearchPdv","color":"white","type":"info","title":"Buscar","classIcon":"fa-solid fa-magnifying-glass","size":"medium","clicked":_vm.execute}}),_c('Button',{attrs:{"_key":"btnSearchPdv","color":"white","type":"info","title":"Limpar","classIcon":"fa-solid fa-broom","size":"medium","clicked":_vm.clean}})],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }