<template>
  <div>
    <SearchByFilter v-model="search" />
    <br />
    <Loading type="line" :center="false" v-show="loading" />
    <Alert type="info" v-if="list.length == 0 && showAlert">
      <span> Nenhuma Locação encontrada! </span>
    </Alert>
    <div v-for="rent in list" :key="rent.id">
      <div class="div-rent">
        <div class="div-item">
          <Molded :borderRadius="12">
            <div>
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">

                  <div class="side-by-side number">
                    <span class="title"> {{ rent.numberAndDate }}</span>
                  </div>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                  <div class="identification max-identification text-center">
                    <span v-if="rent.identification">
                      {{ rent.identification }}</span>
                    <span v-else>Sem identificação</span>
                  </div>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="5" xl="5">
                  <div class="title max-title">
                    <span class="icon-customer"><i class="fa-duotone fa-user-tie"></i></span>
                    {{ rent.customerFullName }}
                  </div>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                  <div class="text-right">
                    <ButtonMovimentStock :showTitleButton="false" :keyButton="rent.id" :rentId="rent.id"
                      :periodRentId="null" />
                  </div>
                </b-col>
              </b-row>
            </div>
          </Molded>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchByFilter from "./SearchByFilter.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ButtonMovimentStock from "../../operational/ButtonMovimentStock.vue";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SearchList",
  props: ["filter"],
  components: { SearchByFilter, Loading, Alert, Molded, ButtonMovimentStock },
  data() {
    return {
      search: {},
      list: [],
      urlGetAll: "/api/v1/pdv/search",
      loading: false,
      showAlert: false,
    };
  },
  mounted() {
    this.list = this.searchRent;
  },
  computed: {
    ...mapState("pdv", ["searchRent"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    ...mapMutations("pdv", ["updateSearchRent"]),
    getAll(filter, search) {
      this.loading = true;
      let params = {
        url: this.urlGetAll,
        obj: {
          filter: filter,
          search: search,
        },
      };
      this.getApi(params).then((response) => {
        this.loading = false;
        this.list = response.content;
        this.updateSearchRent(this.list);
        this.showAlert = true;
        this.removeLoading(["btnSearchPdv"]);
      });
    },
  },
  watch: {
    search: {
      handler(search) {
        this.getAll(search.filter, search.search);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-rent {
  margin-top: 10px;
  margin: auto;
}

.div-item {
  margin-top: 10px;
}

.number {
  font-size: 14px;
  margin-left: 20px;
}

.title {
  font-size: 15px;
}

.identification {
  font-size: 13px;
  background-color: #e0e0e0;
  border-radius: 20px;
  padding: 3px;
}

.icon-customer {
  margin-right: 10px;
}


.max-title {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-identification {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.icon-default {
  font-size: 20px;
  color: #dde3ea;
}
</style>