<template>
  <div class="main">
    <div>
      <Panel module="PDV" title="Pesquisar" :showFilter="false" :showSearch="false" :showButtons="false">
        <div slot="content-main">
          <br />
          <div class="main">
            <SearchList />
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import SearchList from "../../../components/modules/pdv/rent/SearchList.vue";

import { mapMutations } from "vuex";

export default {
  name: "PdvSearchView",
  components: {
    Panel,
    SearchList,
  },

  mounted() {
    this.removeLoading(["panel", "btnPdvSearch"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading", "btnPdvSearch"]),
  },
};
</script>

<style scoped>
.main {
  margin-top: 25px;
}
</style>